<template>
  <div @mouseleave="opened = false" id="select-input-personalizado">
    <b-field :message="errors.first('number')" :type="{ 'is-danger': errors.has('number') }">
      <div :class="{openList:opened}" class="select-input-personalizado">
        <div
          :class="{'select-input-error': errors.has(field)}"
          @click="showList()"
          class="select-input-personalizado-icon-selector"
        >
          <!--BANDERAS-->
          <div class="countriesSelectorContainer">
            <img
              :src="countrieInfo.bandera"
              @error="imageNotFound"
              alt="bandera"
              class="select-input-personalizado-bandera"
            />
            <p>(+{{countrieInfo.indicativo}})</p>
          </div>
          <!--FIN BANDERAS-->
          <div class="dropdown-list">
            <!-- <span class="material-icons">{{!opened? 'expand_more': 'expand_less'}}</span> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 9 5" fill="none" :style="`${opened ? 'transform: rotate(180deg);': ''}`">
              <path d="M8 0.5L4.5 4L1 0.499999" stroke="#444B57" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="divisor"></div>
        </div>
        <div class="select-input-personalizado-center">
          <span class="icon-mks icon-celphone-new" v-if="!newPhoneIcon">phone_iphone</span>
          <span class="cellphone" v-if="newPhoneIcon">
            <i class="icon-mks icon-celphone-new"></i>
          </span>
          <input
            autocomplete="off"
            class="select-input-personalizado-center-text"
            :class="{'with-error-padding' : errors.has('number')}"
            name="number"
            :placeholder="`Celular`"
            type="number"
            v-model="value"
            v-validate="validatedRules"
          />
          <!-- <span class="material-icons error" v-show="errors.has('number')">error_outline</span> -->
          <i class="icon-mks icon-error-alert" v-show="errors.has('number')"></i>
        </div>
      </div>
      <div :style="{display: opened ? 'flex' : 'none', zIndex: 2}" class="dropdown-list-content">
        <div class="filterFlagsContainer">
          <input
            @input="(e)=>filter=e.target.value "
            class="filterFlags"
            placeholder="Escriba el país o el indicativo"
            type="text"
          />
          <span></span>
        </div>
        <div
          :key="'countries#' + IType"
          @click="()=>{valueSelector = itemType.image; showList(); changedPais(itemType)}"
          class="dropdown-list-content-item"
          v-for="(itemType, IType) in filteredCountries"
        >
          <div class="dropdown-list-content-item-left">
            <img :src="itemType.image" @error="imageNotFound" alt="countries" />
          </div>
          <div class="dropdown-list-content-item-right">(+{{itemType.value}}) {{ itemType.label }}</div>
        </div>
      </div>
    </b-field>
  </div>
</template>
<script>
import callingCode from 'country-json/src/country-by-calling-code.json';
import BanderaPais from 'country-json/src/country-by-abbreviation.json';
import _ from 'lodash';

export default {
  props: {
    iconLeft: {
      type: String
    },
    iconRight: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    field: {
      type: String
    },
    fieldSelector: {
      type: String
    },
    type: {
      type: String
    },
    typeData: {
      type: String
    },
    rules: {
      type: String
    },
    required: {
      type: Boolean
    },
    selector: {
      type: Boolean
    },
    indicative: {
      type: String
    },
    flag: {
      type: String
    },
    errorShow: {
      type: Boolean
    },
    initDocument: {
      type: String
    },
    initInputValue: {
      type: String
    },
    initIndicativo: {
      type: Number
    },
    initInfo: {
      type: Object
    },
    formInfo: {
      type: Object
    },
    newPhoneIcon: {
      type: Boolean
    },
    getValidationPhone: {
      type: Boolean
    }
  },
  data () {
    return {
      filter: '',
      value: '',
      countries: [],
      opened: false,
      option: {
        value: 57,
        label: 'Colombia',
        image: 'https://api.precompro.com/banderas/co.png',
        selected: true
      },
      validatedRules: 'numeric|min:7|max:11',
      validated: this.required || false,
      error: null,
      countrieInfo: {
        bandera: 'https://api.precompro.com/banderas/co.png',
        indicativo: 57,
        pais: 'Colombia'
      }
    };
  },
  mounted () {
    this.listCountries();
    this.indicativeParam(this.params.indicativeRegion ? this.params.indicativeRegion : 57);
    this.$emit('value', { countrieInfo: this.countrieInfo, inputValue: this.value });
  },
  methods: {
    formatFilter () {
      return this.filter.toLowerCase();
    },
    showList () {
      this.opened = !this.opened;
    },
    imageNotFound (e) {
      e.target.src = 'https://images.homify.com/c_fill,f_auto,q_0,w_740/v1526483607/p/photo/image/2561426/3.jpg';
    },
    listCountries () {
      const data = [];
      const indicative = null;
      const flag = null;
      _.forEach(BanderaPais, function (item) {
        const info = callingCode.find(i => i.country === item.country);
        if (info) {
          if (info.calling_code !== null) {
            const image = item.abbreviation ? 'https://api.precompro.com/banderas/' + item.abbreviation.toLowerCase() + '.png' : null;
            if (indicative != null && indicative === info.calling_code && flag != null && flag === image && info.country !== 'Bouvet Island') {
              data.push({
                value: info.calling_code,
                label: info.country,
                image: image,
                selected: true
              });
            } else if (info.country !== 'Bouvet Island') {
              data.push({
                value: info.calling_code,
                label: info.country,
                image: image
              });
            }
          }
        }
      });
      this.countries = data;
    },
    indicativeParam (indicative) {
      const selectIndicative = this.countries.find(value => parseInt(value.value) === parseInt(indicative));
      this.option = selectIndicative;
      this.valueSelector = selectIndicative.image;
      this.changedPais(this.option);
    },
    changedPais (indicative) {
      this.option = indicative;
      let aux2 = null;
      const aux3 = indicative.label;
      const ind = indicative.value;
      _.forEach(BanderaPais, function (item) {
        if (ind !== null) {
          const data = item.country;
          if (data === aux3) {
            aux2 = item.abbreviation.toLowerCase();
          }
        }
      });
      this.countrieInfo = { pais: indicative.label, indicativo: indicative.value, bandera: aux2 != null ? 'https://api.precompro.com/banderas/' + aux2 + '.png' : null };
      this.validateTelIndicative(indicative.value);
    },
    validateTelIndicative (indicative) {
      const paisId = (indicative === null) ? 57 : parseInt(indicative);
      switch (paisId) {
        case 57:
          this.validatedRules = 'numeric|min:10|max:10';
          this.validated = true;
          break;
        case 507:
          this.validatedRules = 'numeric|min:7|max:8';
          this.validated = true;
          break;
        default:
          this.validatedRules = 'numeric|min:7|max:11';
          this.validated = true;
          break;
      }
    },
    isValidTel () {
      setTimeout(() => {
        if (this.value !== null && this.value !== '' && this.value !== undefined && !isNaN(this.value)) {
          if (this.errors.first('input') !== undefined) {
            this.error = 'Por favor ingrese un Teléfono válido';
          } else {
            this.error = null;
          }
        } else {
          this.error = null;
        }
      }, 100);
    },
    filteredCountriesMethod () {
      return this.countries.filter((country) =>
        country.label.toLowerCase().includes(this.formatFilter()) || ('+' + country.value.toString()).includes(this.formatFilter())
      );
    }
  },
  watch: {
    countrieInfo () {
      this.$emit('value', { countrieInfo: this.countrieInfo, inputValue: this.value });
    },
    value () {
      this.$emit('value', { countrieInfo: this.countrieInfo, inputValue: this.value });
    },
    'errors.items': function (val, oldVal, event) {
      if (this.getValidationPhone) {
        const existNumberValidation = val.find((validation) => validation.field === 'number');
        this.$emit('getValidationsPhone', !!existNumberValidation);
      }
    }
  },
  computed: {
    filteredCountries () {
      if (this.filter === '') {
        return this.countries;
      } else {
        return this.filteredCountriesMethod();
      }
    }

  }
};
</script>
<style lang="scss">
#select-input-personalizado {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    outline: unset;
  }
}

.filterFlagsContainer {
  background-color: #ecedee;
  padding: 2px;
  display: flex;
  align-items: center;
  width: 90%;
  min-height: 22px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  overflow: hidden;

  input {
    height: 100%;
    width: 100%;
    background-color: transparent;
    color: #606773;
    @media screen and (min-width: 650px) and (max-width: 1025px) {
      font-size: 10.3px;
    }
  }
  span {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    width: 15px;
    height: 14px;
    position: relative;
    right: 3px;
  }
}
#select-input-personalizado {
  width: 100%;
  height: fit-content;
  .filterFlags {
    border: unset;
    outline: none;
  }
  margin-bottom: 3px;
  position: relative;
  @media screen and (min-width: 600px) and (max-width: 1000px) {
    margin-bottom: 5px;
  }
  .dropdown-list-content {
    position: absolute;
    top: 30px;
    left: 0px;
    background-color: #ffffff;
    width: 100%;
    overflow-y: auto;
    max-height: 180px;
    display: none;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
    flex-direction: column;
    border: 1px solid rgba(68, 75, 87, 0.5);
    border-top: solid 1px transparent;
    border-radius: 6px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #606773 !important;
      box-shadow: unset !important;
      border: unset;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      min-height: 28px;
      max-height: 29px;
      padding: 0px 5px;
      cursor: pointer;
      &-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 32px;
        margin: 5px;
        width: 30px;
        font-size: 13px;
        img {
          width: 20px;
          height: 20px;
          border-radius: 99%;
        }
      }
      &-right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 30px;
        width: 100%;
        font-size: 12px;
      }
      &:hover {
        background-color: #ddd;
      }
    }
  }
  .select-input-personalizado {
    display: flex;
    width: 100%;
    height: 30px;
    border: unset;
    border-radius: 6px;
    overflow: hidden;
    &.openList {
      border-bottom: 1px solid transparent;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      position: relative;
      &::after {
        content: " ";
        display: block;
        background-color: #a8adb5;
        height: 1px;
        width: 90%;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 0px;
      }
    }
    &-icon-selector {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      min-width: 92px;
      background-color: white;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      @media screen and (max-width: 600px) {
        width: 90px;
      }
      .dropdown-list {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 5px;
        img {
          cursor: pointer;
          width: 15px;
          height: 7px;
        }
      }
      .divisor {
        width: 1px;
        height: 28px;
        background-color: #a8adb5;
        margin-left: 2px;
      }
    }
    .with-selector {
      border-left: none;
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
    }
    &-center {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 100%;
      position: relative;
      &-text {
        width: 100%;
        height: 30px;
        border-left: none;
        border: unset;
        border-right: none;
        text-indent: 18px;
        text-align: left;
      }
    }
    &-icon-right {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      height: 40px;
      width: 60px;
      border-left: none;
      position: relative;

      &-item {
        display: flex;
        // border-left: 1px solid rgba(68, 75, 87, .5);
        width: 60px;
        justify-content: center;
        align-items: center;
        height: 30px;
        cursor: pointer;
      }
    }
    .borderRight {
      .select-input-personalizado-center-text {
        margin-right: 3px;
      }
    }
    &-bandera {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      cursor: pointer;
    }
    &-text {
      height: 25px;
      width: 25px;
      cursor: pointer;
    }
  }
  .select-input-item {
    border: none;
    border-right: 1px solid rgba(68, 75, 87, 0.5);
    height: 30px;
    width: 50px;
  }
  .w-100px {
    width: 100px;
  }
}
.countriesSelectorContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  p {
    font-size: 14px;
  }
}
.cellphone {
  position: absolute;
  left: 0;
  font-size: 20px;
}
span.error {
  color: red;
  position: absolute;
  right: 6px;
  top: 0;
  left: unset;
  bottom: 0;
  margin: auto;
  font-size: 21px;
  width: fit-content;
  height: 22px;
  transform: translateY(1px);
}
</style>
